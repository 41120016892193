import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import x5GMaps from 'x5-gmaps'
import './assets/styles/index.css'

Vue.use(x5GMaps, 'AIzaSyA6dO9jbaZuoEsKrljyctUSWpDtHpBtzHA')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
